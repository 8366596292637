/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from 'react-router-dom';
// import { withAuthentication } from '../components/Session';

//import AdminNavbar from "components/Navbars/userNavbar.jsx";

import Footer from '../components/Footer/Footer';
//import Sidebar from "components/Sidebar/Sidebar.jsx";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import getProfileRoutes from '../routes.js';
import subroutes from '../subroutes.js';

// var ps;

function FadingRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => <Component {...routeProps} {...rest} />}
    />
  );
}

class AdminLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: 'black',
      activeColor: 'info',
      sidebarMini: false,
      routes: getProfileRoutes(), // getProfileRoutes(this.props.profile.customer.federations[0], this.props.profile.userprofile.role)
    };
  }

  componentDidMount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    */
  }
  componentWillUnmount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    */
  }
  componentDidUpdate(e) {}

  getCompProps = (type, properties) => {
    var Type = type;
    return <Type {...properties} />;
  };

  getComp = (type) => {
    var Type = type;
    return <Type />;
  };

  getSubRoutes = (subroutes) => {
    return subroutes.map((prop, key) => {
      return (
        <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          {...this.props}
        />
      );
    });
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/user' || prop.layout === '/admin') {
        return (
          <FadingRoute
            path={prop.layout + prop.path}
            component={prop.component}
            extra={prop.extra}
            key={key}
            {...this.props}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    console.log(this.props);
    const profileRoutes = this.state.routes;
    if (profileRoutes) {
      console.log(profileRoutes);
      return (
        <div>
              <Switch {...this.props}>
                {this.getRoutes(profileRoutes)}
                {this.getSubRoutes(subroutes)}
              </Switch>
              {
                // we don't want the Footer to be rendered on full screen maps page
                this.props.location.pathname.indexOf('full-screen-map') !==
                -1 ? null : (
                  <Footer fluid />
                )
              }
              {
                // we don't want the Footer to be rendered on full screen maps page
                this.props.location.pathname === '/'
                  ? (this.props.location.pathname = '/admin/main')
                  : null
              }
        </div>
      );
    } else {
      return <></>;
    }
  }
}

export default AdminLayout;
