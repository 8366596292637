import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Row,
  Spinner,
} from 'reactstrap'
import { withFirebase } from './components/Fb'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CommuteIcon from '@material-ui/icons/Commute'
import ReceiptIcon from '@material-ui/icons/Receipt';

const DATEFORMAT = 'YYYY-MM'
class ExpensesReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true,
      showchecked: false,
      selecteddate: moment(),
    }
  }

  incDate = (event) => {
    let inc = this.state.selecteddate.add(1, 'months')
    this.setState({
      selecteddate: inc,
    })
  }

  decDate = (event) => {
    let dec = this.state.selecteddate.subtract(1, 'months')
    this.setState({
      selecteddate: dec,
    })
  }


  formatData = () => {
    if (this.state.data && this.state.traveldata) {
      let handledaggregated = {}
      for (let i = 0; i < this.state.traveldata.length; i++) {
        let h = this.state.traveldata[i]
        if (h.start) {
          h.date = moment(h.start).format('YYYY-MM-DD')
          h.month = moment(h.start).format('YYYY-MM')
        }

        if (!handledaggregated[h.month]) handledaggregated[h.month] = []
        handledaggregated[h.month].push(
          <Row key={h.id}>
            <Col className='border-bottom pb-2 pt-2' sm='12'>
              <CommuteIcon /> {moment(h.start).format('DD.MM.YY HH:mm')} -{' '}
              {moment(h.end).format('DD.MM.YY HH:mm')} {h.purpose}{' '}
              <ChevronRightIcon
                onClick={() => {
                  this.props.history.push('/user/traveldet/' + h.id)
                }}
              />
            </Col>
          </Row>
        )
      }
      for (let i = 0; i < this.state.data.length; i++) {
        let h = this.state.data[i]
        if (h.start) {
          h.date = moment(h.start).format('YYYY-MM-DD')
          h.month = moment(h.start).format('YYYY-MM')
        }

        if (!handledaggregated[h.month]) handledaggregated[h.month] = []
        handledaggregated[h.month].push(
          <Row key={h.id}>
            <Col className='border-bottom pb-2 pt-2' sm='12'>
              <ReceiptIcon /> {moment(h.start).format('DD.MM.YY HH:mm')} -{' '}
              {moment(h.end).format('DD.MM.YY HH:mm')} {h.title}{' '}
              <ChevronRightIcon
                onClick={() => {
                  this.props.history.push('/user/expensedet/' + h.id)
                }}
              />
            </Col>
          </Row>
        )
      }
      this.setState({
        handled: handledaggregated,
        loading: false
      })
    }
  }

  componentDidMount() {
    document.body.classList.remove('login-page')
    this.unsubscribeTravelExpenses = this.props.firebase
      .getUserTravelExpenses()
      .onSnapshot((dataSnapshot) => {
        let _datas = []
        dataSnapshot.forEach((doc) =>
          _datas.push({ ...doc.data(), id: doc.id })
        )
        this.setState({
          traveldata: _datas,
        }, this.formatData)
      })
      this.unsubscribeExpenses = this.props.firebase
      .getUserExpenses()
      .onSnapshot((dataSnapshot) => {
        let _datas = []
        dataSnapshot.forEach((doc) =>
          _datas.push({ ...doc.data(), id: doc.id })
        )
        this.setState({
          data: _datas,
        }, this.formatData)
      })

  }

  componentWillUnmount() {
    this.unsubscribeTravelExpenses()
    this.unsubscribeExpenses()
  }

  getAmountofHandled = () => {
    if (this.state.handled[this.state.selecteddate.format(DATEFORMAT)]) {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)]
        .length
    } else {
      return 0
    }
  }

  showHandledList = () => {
    if (
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)] &&
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)].length < 1
    ) {
      return <></>
    } else {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)]
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <Row>
            <Col
              className='ml-auto mr-auto'
              sm='12'
              lg='8'
              md='12'
              xl='8'
              xs='12'
            >
              <Card className='card-login'>
                <CardHeader>
                  <Row>
                    <Col md='9' sm='6'>
                      {this.props.user.email}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col
                      className='d-block d-sm-none'
                      lg='12'
                      md='12'
                      sm='12'
                      xs='12'
                    >
                      <Button color='primary' size='sm' onClick={this.decDate}>
                        &lt;
                      </Button>
                      <Button color='primary' size='sm' onClick={this.incDate}>
                        &gt;
                      </Button>{' '}
                      {this.state.selecteddate.format('MM-YYYY')}
                    </Col>
                    <Col className='d-none d-sm-block' lg='2' md='2' sm='1'>
                      <Button color='primary' size='sm' onClick={this.decDate}>
                        &lt;
                      </Button>
                    </Col>
                    <Col
                      className='pl-5 pt-3 d-none d-sm-block'
                      lg='8'
                      md='8'
                      sm='5'
                    >
                      <strong>
                        {this.state.selecteddate.format('MM-YYYY')}
                      </strong>
                    </Col>
                    <Col
                      className='text-right d-none d-sm-block'
                      lg='2'
                      md='2'
                      sm='1'
                    >
                      <Button color='primary' size='sm' onClick={this.incDate}>
                        &gt;
                      </Button>
                    </Col>
                  </Row>
                  {this.showHandledList()}
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
        </>
      )
    } else {
      return (
        <>
          <Row>
            <Col className='ml-auto mr-auto' lg='4' md='6'>
              <Card className='card-login'>
                <CardHeader>
                  <Spinner size='sm' color='secondary' /> Loading...
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(ExpensesReport)
