import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/firebase-firestore'
import moment from 'moment'
import 'moment/locale/fi'

const config = {
  apiKey: "AIzaSyDVtJoiGAIR5sy3_ndkInugdYSR-ke4GSA",
  authDomain: "winter-cf1.firebaseapp.com",
  databaseURL: "https://winter-cf1.firebaseio.com",
  projectId: "winter-cf1",
  storageBucket: "winter-cf1.appspot.com",
  messagingSenderId: "220264158496",
  appId: "1:220264158496:web:8a4938e0b6c8b41ae03345",
  measurementId: "G-JNETHL1BCL"
}

class Firebase {
  constructor() {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.firestore = app.firestore()
    this.analytics = app.analytics()
    this.storage = app.storage()
    this.customerid = null
  }
  
  getRandomId = () => this.firestore.collection('random').doc().id
  getTimestamp = (dateString) => app.firestore.Timestamp.fromDate(moment(dateString, 'DD.MM.YYYY').toDate())
  getToday = () => app.firestore.Timestamp.fromDate(new Date())

  
  getCustomerRef = () => {
    console.log(this.customerid)
    return this.firestore
      .collection('customers')
      .doc(this.customerid)
  }
  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)
  
  doSignOut = () => this.auth.signOut()
  
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)
  
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password)

  // *** User API ***
  user = (uid) => this.firestore.collection('users').doc(uid);

  // *** Hours API ***

  getCustomerWorks = () => {
    return this.getCustomerRef().collection('hours')
  }

  getEmployee = () => {
    return this.getCustomerRef().collection('employees').doc(this.auth.currentUser.uid)
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }


  getUserWorks = () => {
    return this.getCustomerRef().collection('hours').where('employeeid', '==', this.auth.currentUser.uid)
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }

  getWorkById = (id) => {
    return this.getCustomerRef().collection('hours').doc(id).get()
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + id).once("value")
  }


  setWorkDone = (id) => {
    return this.getCustomerRef().collection('hours').doc(id).set({ checked: true }, { merge: true })
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + index).update({ checked: true })
  }

  addWork = (work, id) => {
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + id).set(work)
    if (!work.changed) {
      work.employeeid = this.auth.currentUser.uid
    }
    return this.getCustomerRef().collection('hours').doc(id).set(work, { merge: true }).then((w) => {
      return Promise.resolve(w)
    })
  }

  addWorkToEmployee = (employeeid, work, id) => {
    work.employeeid = employeeid
    // return this.db.ref('hours/' + customerId + '/' + employee + '/' + id).set(work)
    return this.getCustomerRef().collection('hours').doc(id).set(work).then((w) => {
      return Promise.resolve(w)
    })
  }

  addEmployee = (employee) => {
    return this.getCustomerRef().collection('employees').add(employee).then((u) => {
      let user = employee
      delete user.log
      delete user.firstname
      delete user.lastname
      delete user.title
      return this.firestore.collection('users').doc(u.id).set(user).then(() => {
        return Promise.resolve(employee)
      })
    })
  }
  getEmployees = () => this.getCustomerRef().collection('employees')
  
  addExpense = (expense, id, images) => {
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + id).set(work)
    expense.employeeid = this.auth.currentUser.uid
    if (images && images.length > 0) {
      const storageRef = this.storage.ref();
      const metadata = {
        customMetadata: {
          expenseid: id,
          employeeid: this.auth.currentUser.uid,
          customerid: this.customerid,
          employee: expense.employee,
          email: expense.email,
        }
      }
      const uploads = []
      for (let i = 0; i < images.length; i++) {
        uploads.push(storageRef.child('employees/expenses/' + expense.employeeid + '/' + id + '-' + images[i].name).put(images[i], metadata))
      }
      return Promise.all(uploads).then((result) => {
      // return storageRef.child('employees/expenses/' + expense.employeeid + '/' + id + '-' + images[0].name).put(images[0], metadata).then((snapshot) => {
        return this.getCustomerRef().collection('expenses').doc(id).set(expense, { merge: true })
      }).then((w) => {
        return Promise.resolve(w)
      })
    } else {
      return this.getCustomerRef().collection('expenses').doc(id).set(expense, { merge: true }).then((w) => {
        return Promise.resolve(w)
      })
    }
  }

  addTravelExpense = (expense, id, images) => {
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/' + id).set(work)
    expense.employeeid = this.auth.currentUser.uid
    if (images && images.length > 0) {
      const storageRef = this.storage.ref();
      const metadata = {
        customMetadata: {
          expenseid: id,
          employeeid: this.auth.currentUser.uid,
          customerid: this.customerid,
          employee: expense.employee,
          email: expense.email,
        }
      }

      return storageRef.child('employees/travelexpenses/' + expense.employeeid + '/' + id + '-' + images[0].name).put(images[0], metadata).then((snapshot) => {
        return this.getCustomerRef().collection('travelexpenses').doc(id).set(expense, { merge: true })
      }).then((w) => {
        return Promise.resolve(w)
      })
    } else {
      return this.getCustomerRef().collection('travelexpenses').doc(id).set(expense, { merge: true }).then((w) => {
        return Promise.resolve(w)
      })
    }
  }

  getUserExpenses = () => {
    return this.getCustomerRef().collection('expenses').where('employeeid', '==', this.auth.currentUser.uid)
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }

  getUserTravelExpenses = () => {
    return this.getCustomerRef().collection('travelexpenses').where('employeeid', '==', this.auth.currentUser.uid)
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }

  getUserExpenseById = (id) => {
    return this.getCustomerRef().collection('expenses').doc(id).get()
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }

  getUserTravelExpenseById = (id) => {
    return this.getCustomerRef().collection('travelexpenses').doc(id).get()
    // return this.db.ref('hours/' + customerId + '/' + this.auth.currentUser.uid + '/')
  }


}
export default Firebase