import React from 'react';
import moment from 'moment';
import 'moment/locale/fi';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
//  CardTitle,
//  Input,
//  Container,
//  FormGroup,
//  Label,
  Col,
  Row,
  Spinner,
//  Nav,
//  NavItem,
//  NavLink,
} from 'reactstrap';
// import { Link } from 'react-router-dom';
import { withFirebase } from './components/Fb';
// import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
// import AccessTime from '@material-ui/icons/AccessTime';
import SportsBaseballOutlinedIcon from '@material-ui/icons/SportsBaseballOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import AddCircleIcon from '@material-ui/icons/AddCircle';

const DATEFORMAT = 'YYYY-MM';
class Report extends React.Component {
  constructor(props) {
    super(props);
    // this.onSubmit = this.onSubmit.bind(this)
    // this.buttonBlurRef = React.createRef()
    this.state = {
      data: null,
      loading: true,
      showchecked: false,
      selecteddate: moment(),
    };
  }

  incDate = (event) => {
    let inc = this.state.selecteddate.add('weeks', 1);
    this.setState({
      selecteddate: inc,
    });
  };

  decDate = (event) => {
    let dec = this.state.selecteddate.subtract('weeks', 1);
    this.setState({
      selecteddate: dec,
    });
  };

  toggleChecked = () => {
    this.setState({ showchecked: !this.state.showchecked });
  };

  checkWork = (index) => {
    this.props.firebase.setWorkDone(index);
    this.setState({ clicked: index });
  };

  setMessage = (value) => {
    this.setState({ message: value });
  };

  acceptTerms = (value) => {
    this.setState({ terms: value });
  };

  componentDidMount() {
    document.body.classList.remove('login-page')
    this.unsubscribe = this.props.firebase
      .getUserWorks()
      .onSnapshot(dataSnapshot => {
      //.on('value', (dataSnapshot) => {
        // if (dataSnapshot.exists()) {
          let _datas = []
          dataSnapshot.forEach(doc =>
            _datas.push({ ...doc.data(), id: doc.id }),
          )

          let handledaggregated = {};
          let handledaggregatedSum = {};
          for (let i = 0; i < _datas.length; i++) {
            let h = _datas[i]
            if (!h.duration) {
              h.duration = moment(h.end).diff(moment(h.start), 'hours', true)
            }
            if (h.start) {
              h.date = moment(h.start).format('YYYY-MM-DD')
              h.month = moment(h.start).format('YYYY-MM')
              h.isoweek = moment(h.start).format('YYYY-WW')
            }

            if (h.checked || h.away) {
              if (!handledaggregated[h.isoweek]) handledaggregated[h.isoweek] = [];
              if (!handledaggregatedSum[h.isoweek]) handledaggregatedSum[h.isoweek] = { checked: 0, away: 0 }
              h.away ? handledaggregatedSum[h.isoweek].away += h.duration : handledaggregatedSum[h.month].checked += h.duration
              handledaggregated[h.isoweek].push(
                <Row key={h.id}>
                  <Col className='border-bottom pb-2 pt-2' sm='12'>
                    {h.type === 'coaching' ? (
                      <SportsBaseballOutlinedIcon />
                    ) : (
                      ''
                    )}{' '}
                    {moment(h.start).format('HH:mm')} -{' '}
                    {moment(h.end).format('HH:mm')} {h.task}
                    {h.away ? (
                     ' Poissaolo'
                    ) : (
                      ''
                    )}{' '}
                    <ChevronRightIcon
                      onClick={() => {
                        this.props.history.push('/user/det/' + h.id)
                      }}
                    />
                  </Col>
                </Row>
              );
            }
          }

          this.setState({
            data: _datas,
            // selecteddate: moment(),
            loading: false,
            handled: handledaggregated,
            handledsum: handledaggregatedSum,
          });
          /*
        } else {
          this.setState({
            data: [],
            selecteddate: moment(),
            loading: false,
            nothandled: [],
            handled: [],
          });
        }
        */
      });
    // ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  getAmountofHandled = () => {
    if (this.state.handled[this.state.selecteddate.format(DATEFORMAT)]) {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)]
        .length;
    } else {
      return 0;
    }
  };

  showHandledList = () => {
    if (
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)] &&
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)].length < 1
    ) {
      return <></>;
    } else {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)];
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' sm='12' lg='8' md='12' xl='8' xs='12'>
                <Card className='card-login'>
                  <CardHeader>
                    <Row>
                      <Col md='9' sm='6'>
                        Työajanseuranta / {this.props.user.email}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className='d-block d-sm-none' lg='12' md='12' sm='12' xs='12'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.decDate}
                        >
                          &lt;
                        </Button>
                        
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.incDate}
                        >
                          &gt;
                        </Button>{' '}
                        viikko {this.state.selecteddate.format('WW-YYYY')} ({moment(this.state.selecteddate).startOf('isoWeek').format('D.M')} - {moment(this.state.selecteddate).endOf('isoWeek').format('D.M')})
                      </Col>
                      <Col className='d-none d-sm-block' lg='2' md='2' sm='1'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.decDate}
                        >
                          &lt;
                        </Button>
                      </Col>
                      <Col className='pl-5 pt-3 d-none d-sm-block' lg='8' md='8' sm='5'>
                        <strong>
                          viikko {this.state.selecteddate.format('WW-YYYY')} ({moment(this.state.selecteddate).startOf('isoWeek').format('D.M')} - {moment(this.state.selecteddate).endOf('isoWeek').format('D.M')})
                        </strong>
                      </Col>
                      <Col className='text-right d-none d-sm-block' lg='2' md='2' sm='1'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.incDate}
                        >
                          &gt;
                        </Button>
                      </Col>
                    </Row>
                    {this.showHandledList()}
                    <Row>
                      <Col className='border-bottom pb-2 pt-2 text-right' sm='8'>
                        Kuitattuja: {(this.state.handledsum[this.state.selecteddate.format(DATEFORMAT)] ? this.state.handledsum[this.state.selecteddate.format(DATEFORMAT)].checked : 0)} h
                      </Col>
                      <Col className='border-bottom pb-2 pt-2 text-right' sm='4'>
                        Poissaoloja: {(this.state.handledsum[this.state.selecteddate.format(DATEFORMAT)] ? this.state.handledsum[this.state.selecteddate.format(DATEFORMAT)].away : 0)} h
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
        </>
      );
    } else {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' lg='4' md='6'>
                <Card className='card-login'>
                  <CardHeader>
                    <Spinner size='sm' color='secondary' /> Loading...
                  </CardHeader>
                </Card>
              </Col>
            </Row>
        </>
      );
    }
  }
}

export default withFirebase(Report);
