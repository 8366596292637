import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  //  Container,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap'
import { withFirebase } from './components/Fb'

// import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TextField from '@material-ui/core/TextField'
// import { withAuthentication } from './components/Session'

const TYPES = [
  { label: 'Koulutus', value: 'education' },
  { label: 'Toimisto', value: 'office' },
  { label: 'Valmennus', value: 'coachingmanual' },
  { label: 'Kahvio/Vastaanotto', value: 'reception' },
  { label: 'Iltapäiväkerho', value: 'kerho' },
  { label: 'Yksityistunnit', value: 'private' },
  { label: 'Kilpailut', value: 'competition' },
  { label: 'Muut työt', value: 'other' },
]

// const COSTCENTER = [
//   { label: 'Aikuisvalmennus', value: 'adults' },
//   { label: 'Juniorivalmennus', value: 'juniors' },
//   { label: 'Kilpavalmennus', value: 'competitive' },
// ]

class Add extends React.Component {
  constructor(props) {
    super(props)
    // this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      title: null,
      date: moment(),
      start: moment().format('HH:00'),
      end: moment().add(1, 'hours').format('HH:00'),
      description: '',
      type: 'other',
    }
  }

  componentDidMount() {}

  setStartDate = (event) => {
    console.log(event.target.value)
    this.setState({ date: moment(event.target.value) })
  }

  setEndtime = (event) => {
    this.setState({ end: event.target.value })
  }

  setStarttime = (event) => {
    this.setState({ start: event.target.value })
  }

  setTitle = (event) => {
    this.setState({ title: event.target.value })
  }

  setDescription = (event) => {
    this.setState({ description: event.target.value })
  }

  setType = (event) => {
    this.setState({ type: event.target.value })
  }

  onSave = (event) => {
    event.preventDefault()
    console.log('Save')
    // const key = uuidv4();
    const now = moment().unix()
    const work = {
      checked: false,
      start: this.state.date.format('YYYY-MM-DD') + 'T' + this.state.start,
      end: this.state.date.format('YYYY-MM-DD') + 'T' + this.state.end,
      log: {},
      message: this.state.description,
      task: this.state.title,
      employee: this.props.profile.displayName,
      email: this.props.profile.email,
      type: this.state.type,
    }
    work.log[now] = { timestamp: now, type: 'created', user: 'user' }
    this.props.firebase.addWork(work, uuidv4()).then((dataSnapshot) => {
      this.props.history.push('/user/main')
    })
  }

  render() {
    return (
      <>
        <Row>
          <Col className='ml-auto mr-auto' sm='12' lg='8' md='12' xl='8' xs='12'>
            <Card className='card-login'>
              <CardHeader>Lisää työaika</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='title'>Otsikko</Label>
                      <Input type='text' name='title' id='title' placeholder='Otsikko' onChange={this.setTitle} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='type'>Tyyppi</Label>
                      <Input
                        type='select'
                        name='type'
                        id='type'
                        onChange={this.setType}
                        defaultValue={this.state.type ? this.state.type : ''}
                      >
                        <option disabled>Tyyppi ...</option>
                        {TYPES.map((t) => (
                          <option key={t.value} value={t.value}>
                            {t.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='description'>Kuvaus</Label>
                      <Input type='textarea' name='description' id='description' onChange={this.setDescription} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm='12'>
                    <TextField
                      id='date'
                      label='Päivä'
                      type='date'
                      onChange={this.setStartDate}
                      defaultValue={moment().format('YYYY-MM-DD')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <br />
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <TextField
                      id='starttime'
                      label='Alkuaika'
                      type='time'
                      onChange={this.setStarttime}
                      defaultValue={moment().format('HH:00')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600, // 5 min
                      }}
                    />
                  </Col>
                  <Col sm='6'>
                    <TextField
                      id='endtime'
                      label='Loppuaika'
                      type='time'
                      onChange={this.setEndtime}
                      defaultValue={moment().add(1, 'hours').format('HH:00')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600, // 5 min
                      }}
                    />
                  </Col>
                </Row>
                <Row className='pt-5'>
                  <Col className='ml-auto mr-auto' sm='8'>
                    <Button color='primary' onClick={this.onSave}>
                      Tallenna
                    </Button>
                  </Col>
                  <Col className='ml-auto mr-auto' sm='4'>
                    <Button
                      color='secondary'
                      onClick={() => {
                        this.props.history.push('/user/main')
                      }}
                    >
                      Peruuta
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default withFirebase(Add)
