import React from 'react'
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from 'react-router-dom'
import UserNavbar from '../components/Navbars/UserNavbar'
import Footer from '../components/Footer/Footer'

import getProfileRoutes from '../routes.js'
import subroutes from '../subroutes.js'
import { Container } from 'reactstrap'

function FadingRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => <Component {...routeProps} {...rest} />}
    />
  )
}

class UserLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      backgroundColor: 'black',
      activeColor: 'info',
      sidebarMini: false,
      routes: getProfileRoutes(), // getProfileRoutes(this.props.profile.customer.federations[0], this.props.profile.userprofile.role)
    }
  }

  componentDidMount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    */
  }
  componentWillUnmount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
    */
  }
  componentDidUpdate(e) {}

  getCompProps = (type, properties) => {
    var Type = type
    return <Type {...properties} />
  }

  getComp = (type) => {
    var Type = type
    return <Type />
  }

  getSubRoutes = (subroutes) => {
    return subroutes.map((prop, key) => {
      return (
        <FadingRoute
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
          {...this.props}
        />
      )
    })
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/user') {
        // var c = this.getComp(prop.component)
        // console.log(c)
        return (
          <FadingRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            {...this.props}
          />
          /*
          <Route
            path={prop.layout + prop.path}
            component={c}
            key={key}
          />
          */
        )
      } else {
        return null
      }
    })
  }
  render() {
    if (this.props.location && this.props.location.pathname === '/auth/login') {
      return <Redirect from='/auth/login' to='/user/main' />
    } else {
      // console.log(this.props)
      const profileRoutes = this.state.routes
      if (profileRoutes) {
        // console.log(profileRoutes);
        return (
          <>
            <UserNavbar {...this.props} />
            <div className='wrapper wrapper-full-page'>
              <div className='full-page section-image'>
                <div>
                  <Container>
                    <Switch {...this.props}>
                      {this.getRoutes(profileRoutes)}
                      {this.getSubRoutes(subroutes)}
                      <Redirect from='/auth/login' to='/user/main' />
                    </Switch>
                  </Container>
                  <div
                    className='full-page-background'
                    style={{
                      backgroundImage: `url(${require('../assets/img/bg/miguel-perales.jpg')})`,
                    }}
                  />

                  {
                    // we don't want the Footer to be rendered on full screen maps page
                    this.props.location.pathname.indexOf('full-screen-map') !==
                    -1 ? null : (
                      <Footer fluid />
                    )
                  }
                  {
                    // we don't want the Footer to be rendered on full screen maps page
                    this.props.location.pathname === '/'
                      ? (this.props.location.pathname = '/user/main')
                      : null
                  }
                </div>
              </div>
            </div>
          </>
        )
      } else {
        return <></>
      }
    }
  }
}

export default UserLayout
