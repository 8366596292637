/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Add from './Add'
import Main from './Main'
import Report from './Report'
import Expense from './Expense'
import TravelExpense from './TravelExpense'
import ExpensesReport from './ExpensesReport'


const routes = [
  {
    path: '/main',
    name: 'Main',
    icon: 'nc-icon nc-layout-11',
    component: Main,
    layout: '/user'
  },
  {
    path: '/add',
    name: 'Add',
    icon: 'nc-icon nc-layout-11',
    component: Add,
    layout: '/user'
  },
  {
    path: '/report',
    name: 'Report',
    icon: 'nc-icon nc-layout-11',
    component: Report,
    layout: '/user'
  },
  {
    path: '/expense',
    name: 'Expense',
    icon: 'nc-icon nc-layout-11',
    component: Expense,
    layout: '/user'
  },
  {
    path: '/travel',
    name: 'TravelExpense',
    icon: 'nc-icon nc-layout-11',
    component: TravelExpense,
    layout: '/user'
  },
  {
    path: '/expensesreport',
    name: 'ExpensesReport',
    icon: 'nc-icon nc-layout-11',
    component: ExpensesReport,
    layout: '/user'
  }

]

const getProfileRoutes = () => {
  return routes
}

/*
const _routes = {
  default: routes
}
*/

export default getProfileRoutes
