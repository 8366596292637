import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  //  Container,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap'
import { withFirebase } from './components/Fb'

import 'react-datepicker/dist/react-datepicker.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import ImageUploader from 'react-images-upload'

class Expense extends React.Component {
  constructor(props) {
    super(props)
    // this.onSubmit = this.onSubmit.bind(this)
    this.state = {
      title: null,
      description: '',
      pictures: [],
      loading: true
    }
  }

  async componentDidMount() {
    if (this.props.computedMatch.params.id) {
      console.log('this is update: ' + this.props.computedMatch.params.id)
      let expense = await this.props.firebase.getUserExpenseById(this.props.computedMatch.params.id)
      console.log(expense.data())
      this.setState({ id:this.props.computedMatch.params.id, title: expense.data().title, expenserows: expense.data().rows, loading: false })
    } else {
      console.log('this is new')
      this.setState({ loading: false })
    }
  }

  setTitle = (event) => {
    this.setState({ title: event.target.value })
  }

  setSum = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.expenserows
    let _s = event.target.value
    _a[index].sum = _s
    this.setState({ expenserows: _a })
  }

  setDescription = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.expenserows
    _a[index].description = event.target.value
    this.setState({ expenserows: _a })
  }

  addExpenseRow = () => {
    let j = this.state.expenserows
    j.push({})
    this.setState({ expenserows: j })
  }

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({
      pictures: pictureFiles,
    })
  }

  expenseRows = () => {
      if (!this.state.expenserows) {
        this.setState({ expenserows: new Array(1).fill({}) })
      } else {
        return this.state.expenserows.map((row, index) => {
          let i = index
          return (
            <Row key={i} style={{ border: '1px solid rgba(0, 0, 0, 0.10)' }}>
              <Col sm='10' md='10' xs='10'>
                <FormGroup>
                  <Label for={'description.' + i}>Kuvaus</Label>
                  <Input
                    type='text'
                    name={'description.' + i}
                    id={'description.' + i}
                    onChange={this.setDescription}
                    value={(this.state.expenserows[i].description ? this.state.expenserows[i].description : '')}
                  />
                </FormGroup>
              </Col>
              <Col md='2' sm='2' xs='2'>
                <FormGroup>
                  <Label for={'sum.' + i}>Summa</Label>
                  <Input
                    type='text'
                    name={'sum.' + i}
                    id={'sum.' + i}
                    onChange={this.setSum}
                    placeholder='0.00'
                    value={(this.state.expenserows[i].sum ? this.state.expenserows[i].sum : '')}
                  />
                </FormGroup>
              </Col>
            </Row>
          )
        })
      }
  }

  onSave = (event) => {
    event.preventDefault()
    console.log('Save')
    let _rows = this.state.expenserows.map((r) => {
      if (typeof r.sum !== 'number') {
        r.sum = Number.parseFloat(r.sum.replace(',', '.'))
      }
      return r
    })
    const expense = {
      checked: false,
      rows: _rows,
      title: this.state.title,
      employee: this.props.profile.displayName,
      email: this.props.profile.email,
      start: moment().format('YYYY-MM-DD') + 'T00:00',
      type: 'expense',
    }
    console.log(expense)
    const id = (this.state.id ? this.state.id : uuidv4())
    this.props.firebase
      .addExpense(expense, id, this.state.pictures)
      .then((dataSnapshot) => {
        this.props.history.push('/user/expensesreport')
      })
  }

  render() {
    if (this.state.loading) {
      return (<></>)
    } else {

      return (
        <>
          <Row>
            <Col
              className='ml-auto mr-auto'
              sm='12'
              lg='8'
              md='12'
              xl='8'
              xs='12'
            >
              <Card className='card-login'>
                <CardHeader>Kulukorvaus</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for='title'>Otsikko</Label>
                        <Input
                          type='text'
                          name='title'
                          id='title'
                          placeholder='Otsikko'
                          value={(this.state.title ? this.state.title : '')}
                          onChange={this.setTitle}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md='12' sm='12'>
                      <ImageUploader
                        withIcon={false}
                        buttonText='Lisää kuitit'
                        withLabel={false}
                        onChange={this.onDrop}
                        imgExtension={[
                          '.jpg',
                          '.gif',
                          '.png',
                          '.gif',
                          '.pdf',
                          '.jpeg',
                        ]}
                        maxFileSize={5242880}
                        withPreview={true}
                      />
                    </Col>
                  </Row> */}
                  {this.expenseRows()}
                  <Row>
                    <Col style={{ marginTop: '20px' }}>
                      <AddCircleIcon
                        onClick={() => {
                          this.addExpenseRow()
                        }}
                      />{' '}
                    Lisää kulurivi
                  </Col>
                  </Row>
                  <Row className='pt-5'>
                    <Col className='ml-auto mr-auto' sm='8'>
                      <Button color='primary' onClick={this.onSave}>
                        Tallenna
                    </Button>
                    </Col>
                    <Col className='ml-auto mr-auto' sm='4'>
                      <Button
                        color='secondary'
                        onClick={() => {
                          this.props.history.push('/user/main')
                        }}
                      >
                        Peruuta
                    </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )
    }
  }
}

export default withFirebase(Expense)
