import React from 'react';

import { AuthUserContext, UserProfileContext } from './context';
import { withFirebase } from '../Fb';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        profile: null
      };
    }

    updateDemoTick = (d) => {
      let _profile = this.state.profile
      _profile.demoticks[d] = true
      return _profile
    }

    componentDidMount() {
      console.log('start auth listener')
      this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          // this.props.firebase.getEmployee().get().then((employeeProfile) => {
          this.props.firebase.user(authUser.uid).get().then(userprofile => {
            if (userprofile.exists) { 
              this.props.firebase.customerid = userprofile.data().customerIds[0]
              this.props.firebase.getEmployee().get().then((employeeProfile) => {
                let uProfile = userprofile.data()
                uProfile.employee = employeeProfile.data()
                this.setState({ authUser: authUser, profile: uProfile })
              })
            }
            else {
              this.props.firebase.doSignOut()
            }
          })
         // this.setState({ authUser: authUser })
        } else {
            this.setState({ authUser: null, profile: null, customer: null, clubdata: null });
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <UserProfileContext.Provider value={this.state.profile}>
            <Component {...this.props} authUser={this.state.authUser} profile={this.state.profile} />
          </UserProfileContext.Provider>
        </AuthUserContext.Provider>
        );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;