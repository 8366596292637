/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
// import { Route, Switch, Redirect } from "react-router-dom";

import AuthNavbar from '../components/Navbars/AuthNavbar'
// import Footer from '../components/Footer/Footer'

// import routes from "../subroutes.js";
import Login from '../Login'
import Forget from '../Forget'
// var ps;

class AuthLayout extends React.Component {

  componentDidMount () {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.fullPages);
    }
    */
  }

  componentWillUnmount () {
    // console.log('umount')
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    */
  }

  /*
  componentDidUpdate (prevProps) {

    console.log('componentDidUpdate')
    console.log('Rrow update diff:')

    const now = Object.entries(this.props)
    const added = now.filter(([key, val]) => {
      if (prevProps[key] === undefined) return true
      if (prevProps[key] !== val) {
        console.log(`${key}
          - ${JSON.stringify(val)}
          + ${JSON.stringify(prevProps[key])}`)
      }
      return false
    })
    added.forEach(([key, val]) => console.log(`${key}
          + ${JSON.stringify(val)}`));

//          console.log(prevProps)
//          if (this.props.location.pathname !== '/auth/login') {
//            this.props.history.push('/auth/login')
//          }
      
        }
*/
  /*
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
*/

  render () {
    return (
      <>
        <AuthNavbar />
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image">
              {this.props.forget ?
                <Forget />
                :
                <Login />}
            {/* <Switch>{this.getRoutes(routes)}</Switch>
            <Footer fluid /> */}
          </div>
        </div>
      </>
    );
  }
}

export default AuthLayout;
