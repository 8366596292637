/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import classnames from 'classnames'
// import { NavLink } from "react-router-dom";
import { withFirebase } from '../Fb';
import { NavLink as RouterNavLink } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from 'reactstrap'

class UserNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: 'bg-primary',
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateColor)
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    }
    // if (!this.state.collapseOpen) {
    //   newState["color"] = "bg-white";
    // } else {
    //   newState["color"] = "navbar-transparent";
    // }
    this.setState(newState)
  }

  logOut = () => {
    this.props.firebase.doSignOut()
    this.props.history.push('/auth/login')
  };

  render() {
    return (
      <Navbar
        className={classnames('navbar-absolute fixed-top', this.state.color)}
        expand='lg'
      >
        <Container>
          <div className='navbar-wrapper'>
            <NavbarBrand href='#pablo' onClick={(e) => e.preventDefault()}>
              Cintoia Feel Tracker
            </NavbarBrand>
          </div>
          <button
            aria-controls='navigation-index'
            aria-expanded={false}
            aria-label='Toggle navigation'
            className='navbar-toggler'
            data-toggle='collapse'
            type='button'
            onClick={this.toggleCollapse}
          >
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
            <span className='navbar-toggler-bar navbar-kebab' />
          </button>
          <Collapse
            isOpen={this.state.collapseOpen}
            className='justify-content-end'
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/main' activeClassName=''>Etusivu</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/add' activeClassName=''>Lisää työvuoro</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/expense' activeClassName=''>Lisää kulukorvaus</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/travel' activeClassName=''>Lisää matkalasku</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/report' activeClassName=''>Kuitatut</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={RouterNavLink} to='/user/expensesreport' activeClassName=''>Kulut</NavLink>
              </NavItem>
              <NavItem onClick={()=> this.logOut() }>
                <NavLink to='#'>Logout</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default withFirebase(UserNavbar)
