import React from 'react'
import { withFirebase } from './components/Fb'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  //  Label,
  //  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap'

const INITIAL_STATE = {
  email: '',
  error: null,
  submitted: false,
  validated: false,
}

const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

class Forget extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.setState({ submitted: true })
    const { email } = this.state
    console.log('send reset to ' + email)
    this.props.firebase.doPasswordReset(email).then(() => {
      this.setState({ ...INITIAL_STATE, message: 'Salasanan nollauslinkki lähetetty sähköpostiin ' + email })
    }).catch((error) => {
      this.setState({ ...INITIAL_STATE, error: error, message: 'Salasanan nollauslinkin lähetys epäonnistui' })
    })
  }

  setEmail = (value) => {
    this.setState({ email: value, validated: validateEmail(value) })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter' && this.state.validated) {
      this.onSubmit(event)
    }
  }

  componentDidMount() {
    document.body.classList.toggle('login-page')
  }
  componentWillUnmount() {
    document.body.classList.toggle('login-page')
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' lg='4' md='6'>
              <Form action='' className='form' method=''>
                <Card className='card-login'>
                  <CardHeader>
                    <CardHeader>
                      <h3 className='header text-center'>Lähetä salasanan nollauslinkki</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='nc-icon nc-single-02' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='text'
                        value={this.state.email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        onKeyPress={this.handleKeyPress}
                      />
                    </InputGroup>
                    <br />
                    {this.state.message ? <p className='text-center'>{this.state.message}</p> : (
                    <Button
                      disabled={this.state.submitted || !this.state.validated}
                      block
                      className='btn-round mb-3'
                      color='warning'
                      onClick={this.onSubmit}
                    >
                      Lähetä
                    </Button>
                    )}
                  </CardBody>
                  <CardFooter>
                  <Button
                      block
                      className='btn-round mb-3'
                      color='link'
                      size='sm'
                      onClick={() => window.location.href = '/auth/login'}
                    >
                      Kirjautumiseen
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default withFirebase(Forget)
