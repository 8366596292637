import React from 'react';
import moment from 'moment';
import 'moment/locale/fi';
// import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
//  Container,
  FormGroup,
  Label,
  Col,
  Row,
  Spinner,
  Collapse,
} from 'reactstrap';
import { withFirebase } from './components/Fb';
import 'react-datepicker/dist/react-datepicker.css';
import TextField from '@material-ui/core/TextField';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updated: false,
      fulltime: true
    };
  }

  setStartDate = (event) => {
    console.log(event.target.value);
    this.setState({ date: moment(event.target.value) });
  };

  setEndtime = (event) => {
    this.setState({ end: event.target.value });
  };

  setStarttime = (event) => {
    this.setState({ start: event.target.value });
  };

  setTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  setDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  setComment = (event) => {
    this.setState({ comment: event.target.value, updated: true });
  };

  setChecked = (event) => {
    if (this.state.away) {
      this.setState({
        away: !this.state.away,
        checked: !this.state.checked,
        updated: true,
      });
    } else {
      this.setState({ checked: !this.state.checked, updated: true });
    }
  };

  setAway = (event) => {
    if (this.state.checked) {
      this.setState({
        away: !this.state.away,
        checked: !this.state.checked,
        updated: true,
      });
    } else {
      this.setState({ away: !this.state.away, updated: true });
    }
  };

  setReplacer = (event) => {
    this.setState({ replacer: event.target.value })
  };

  onSave = (event) => {
    event.preventDefault();
    console.log('Save: ' + this.props.computedMatch.params.id);
    const now = moment().unix();
    const work = {
      checked: this.state.checked ? true : false,
      away: this.state.away === 'undefined' ? false : this.state.away,
      comment: this.state.checked ? '' : this.state.comment,
      start: this.state.data.start,
      end: this.state.data.end,
      log: this.state.data.log,
      message: this.state.data.message,
      task: this.state.data.task,
      type: this.state.data.type,
    };
    if (this.state.replacer) {
      if (!work.changed) work.changed = []
      work.changed.push(this.state.replacer)
      let _r = this.state.empmap[this.state.replacer]
      work.email = _r.email
      work.employee = _r.firstname + ' ' + _r.lastname
      work.employeeid = this.state.replacer
    }

    if (!work.log) work.log = {}
    work.log[now] = { timestamp: now, type: 'edit', user: 'user' };
    console.log(work);
    this.props.firebase
      .addWork(work, this.props.computedMatch.params.id)
      .then((dataSnapshot) => {
        this.props.history.push('/user/main');
      });
  };

  employeeSelectOptions = () => {
    if (this.state.employees) {
      return this.state.employees.map((e) => {
        return (<option key={e.id} value={e.id}>{e.firstname} {e.lastname}</option>)
      })
    } else {
      return (<></>)
    }
  }


  componentDidMount() {
    // document.body.classList.toggle('login-page');
    // console.log('id: ' + this.props.computedMatch.params.id + ', user: ' + this.props.user.uid)
    this.props.firebase
      .getWorkById(this.props.computedMatch.params.id)
      .then((dataSnapshot) => {
        if (dataSnapshot.exists) {
          console.log(dataSnapshot.data());
          this.setState({
            data: dataSnapshot.data(),
            id: this.props.match.params.id,
            loading: false,
            checked: dataSnapshot.data().checked,
            date: moment(dataSnapshot.data().starttime).format('YYYY-MM-DD'),
            away: !dataSnapshot.data().away ? false : dataSnapshot.data().away,
            comment: dataSnapshot.data().comment
              ? dataSnapshot.data().comment
              : '',
          });
        }
      });
    if (this.props.profile.employee.salarymodel === 'hourly') {
      this.unsubscribeemp = this.props.firebase
      .getEmployees()
      .onSnapshot((snapshot) => {
        let employees = [];
        let empmap = {}
        snapshot.forEach((doc) => {
          console.log(doc.data());
          let _d = { ...doc.data(), id: doc.id };
          employees.push(_d);
          empmap[doc.id] = doc.data()
        });
        this.setState({
          employees: employees,
          empmap: empmap
        });
      });

    }
  }



  render() {
    if (this.state.loading) {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' lg='4' md='8'>
                <Card className='card-login'>
                  <CardHeader>
                    <Spinner size='sm' color='secondary' /> Loading...
                  </CardHeader>
                </Card>
              </Col>
            </Row>
        </>
      );
    } else {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' lg='8' md='8'>
                <Card className='card-login'>
                  <CardHeader>Tiedot</CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for='title'>Otsikko</Label>
                          <Input
                            type='text'
                            name='title'
                            id='title'
                            placeholder='Otsikko'
                            disabled={true}
                            value={this.state.data.task}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for='description'>Kuvaus</Label>
                          <Input
                            type='textarea'
                            name='description'
                            id='description'
                            disabled={true}
                            value={(this.state.data.message ? this.state.data.message : '')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='12'>
                        <TextField
                          id='date'
                          label='Päivä'
                          type='date'
                          disabled={true}
                          onChange={this.setStartDate}
                          defaultValue={this.state.date}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <br />
                        <br />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm='6'>
                        <TextField
                          id='starttime'
                          label='Alkuaika'
                          type='time'
                          disabled={true}
                          onChange={this.setStarttime}
                          defaultValue={moment(this.state.data.start).format('HH:mm')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 600, // 5 min
                          }}
                        />
                      </Col>
                      <Col sm='6'>
                        <TextField
                          id='endtime'
                          label='Loppuaika'
                          type='time'
                          disabled={true}
                          onChange={this.setEndtime}
                          defaultValue={moment(this.state.data.end).format('HH:mm')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 600, // 5 min
                          }}
                        />
                      </Col>
                  </Row>
                  {(!this.state.data.status ?
                    <>
                    <Row>
                    <Col className='border-top pb-2 mt-4 pt-2' sm='12'>
                    <FormGroup check className="mt-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type='checkbox'
                              name='checked'
                              id='checked'
                              onChange={this.setChecked}
                              checked={this.state.checked}
                            />
                            Tehty
                            <span className='form-check-sign'>
                            </span>
                          </Label>
                        </FormGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='pb-2 mt-2 pt-2' sm='12'>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type='checkbox'
                              name='away'
                              id='away'
                              onChange={this.setAway}
                              checked={this.state.away}
                            />{' '}
                            Poissa
                            <span className='form-check-sign'>
                              <span className='check'></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </Col>
                      </Row>
                      </>
                   : <Row><Col className='border-top pb-2 mt-4 pt-2' sm='12'><div>Hyväksynnässä: {this.state.data.status}</div></Col></Row> )}
                  <Collapse isOpen={this.state.away}>
                  {this.props.profile.employee.salarymodel === 'monthly' ?
                    (
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for='comment'>Kommentti</Label>
                            <Input
                              type='textarea'
                              name='comment'
                              id='comment'
                              onChange={this.setComment}
                              value={this.state.comment}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                   )
                    :
                    (<Row>
                      <Col>
                        <FormGroup>
                          <Label for='replacer'>Tuuraaja</Label>
                          <Input
                            type='select'
                            name='replacer'
                            id='replacer'
                            onChange={this.setReplacer}
                            defaultValue={(this.state.replacer ? this.state.replacer : '')}
                          >
                              <option disabled>Tuuraaja ...</option>
                              
              {this.employeeSelectOptions()}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>)
                    }
                     </Collapse>

                    <Row className='pt-5'>
                    <Col className='ml-auto mr-auto' sm='8'>
                      {(!this.state.data.status ?
                        <Button
                          color='primary'
                          onClick={this.onSave}
                          disabled={!this.state.updated}
                        >
                          {' '}
                          Tallenna
                        </Button>
                        : <></>)}
                      </Col>
                      <Col className='ml-auto mr-auto' sm='4'>
                        <Button
                          color='secondary'
                        onClick={() => {
                          this.props.history.push('/user/main')
                          }}
                        >
                          Peruuta
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </>
      );
    }
  }
}

export default withFirebase(Details)
