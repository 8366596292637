import Login from './Login'
import Details from './Details'
import Expense from './Expense'
import TravelExpense from './TravelExpense'
import Forget from './Forget'

var subroutes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'nc-icon nc-layout-11',
    component: Login,
    layout: '/auth'
  },
  {
    path: '/forget',
    name: 'Forget',
    icon: 'nc-icon nc-layout-11',
    component: Forget,
    layout: '/auth',
  },
  {
    path: '/det/:id',
    name: 'Details',
    icon: 'nc-icon nc-layout-11',
    component: Details,
    layout: '/user'
  },
  {
    path: '/expensedet/:id',
    name: 'Expense',
    icon: 'nc-icon nc-layout-11',
    component: Expense,
    layout: '/user'
  },
  {
    path: '/traveldet/:id',
    name: 'TravelExpense',
    icon: 'nc-icon nc-layout-11',
    component: TravelExpense,
    layout: '/user'
  }

]
export default subroutes
