/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { withFirebase } from './components/Fb';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  //  Label,
  //  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap';

// import { Link } from 'react-router-dom';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch((error) => {
        alert(error.message);
        this.setState({ error });
      });
  };

  setEmail = (value) => {
    this.setState({ email: value })
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSubmit(event)
    }
  };

  setPassword = (value) => {
    this.setState({ password: value })
  };

  componentDidMount() {}
  componentWillUnmount() { }

  // shouldComponentUpdate(nextProps, nextState) {}

  OnForgetPassword = () => {
    window.location.href = '/auth/forget'
  }

  render() {
    document.body.classList.add('login-page')
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className='ml-auto mr-auto' lg="4" md="6">
              <Form action='' className='form' method=''>
                <Card className='card-login'>
                  <CardHeader>
                    <CardHeader>
                      <h3 className='header text-center'>Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='nc-icon nc-single-02' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Email'
                        type='text'
                        // value={email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        onKeyPress={this.handleKeyPress}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                          <i className='nc-icon nc-key-25' />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder='Password'
                        type='password'
                        autoComplete='off'
                        onChange={(e) => this.setPassword(e.target.value)}
                        onKeyPress={this.handleKeyPress}
                      />
                    </InputGroup>
                    <br />
                    <Button
                      block
                      className='btn-round mb-3'
                      color='warning'
                      onClick={this.onSubmit}
                    >
                      Login
                    </Button>
                    <br />
                    <Button
                      block
                      className='btn-round mb-3'
                      color='link'
                      size='sm'
                      onClick={this.OnForgetPassword}
                    >
                      Salasana unohtunut
                    </Button>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className='full-page-background'
          style={{
            backgroundImage: `url(${require('./assets/img/bg/miguel-perales.jpg')})`,
          }}
        />
      </div>
    );
  }
}

export default withFirebase(Login);
