import React from 'react'

import { AuthUserContext, UserProfileContext } from '../Session'
import { Redirect } from 'react-router-dom'
import UserLayout from '../../layouts/UserLayout'
import AuthLayout from '../../layouts/AuthLayout'
import AdminLayout from '../../layouts/AdminLayout'

const WhereToGo = (props) => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (
        <UserProfileContext.Consumer>
          {(profile) => {
            if (authUser) {
              // console.log('Auth user get => UserLayout')
              // console.log(profile)
              if (profile.role === 'ADMIN') {
                return (
                  <AdminLayout {...props} user={authUser} profile={profile} />
                )
              } else if (
                profile.role === 'USER' ||
                profile.role === 'EMPLOYEE'
              ) {
                return (
                  <>
                    <UserLayout {...props} user={authUser} profile={profile} />
                  </>
                )
              }
            } else if (
              props.location &&
              props.location.pathname === '/auth/login'
            ) {
              return <AuthLayout {...props} user={authUser} />
            } else if (props.location && props.location.pathname === '/auth/forget') {
              return <AuthLayout {...props} user={authUser} forget={true} />
            } else {
              console.log('No auth user get yet => AuthLayout')
              // if (props.location && props.location.pathname !== '/auth/login') {
              //   props.history.push('/auth/login')
              // }
              return <Redirect push to='/auth/login' />
              // return <AuthLayout {...props} user={authUser} />
            }
          }}
        </UserProfileContext.Consumer>
      )}
    </AuthUserContext.Consumer>
  </div>
)

export { WhereToGo }
