import React from 'react';
import moment from 'moment';
import 'moment/locale/fi';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  // Container,
  FormGroup,
  Label,
  Col,
  Row,
  Spinner,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { withFirebase } from './components/Fb';
import AccessTime from '@material-ui/icons/AccessTime';
import SportsBaseballOutlinedIcon from '@material-ui/icons/SportsBaseballOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const DATEFORMAT = 'YYYY-MM-DD';
class Main extends React.Component {
  constructor(props) {
    super(props)
    if (props.location.pathname !== '/user/main') {
      props.history.push('/user/main')
    }
    this.state = {
      data: null,
      loading: true,
      showchecked: false,
      selecteddate: moment(),
    };
  }

  incDate = (event) => {
    let inc = this.state.selecteddate.add('days', 1);
    this.setState({
      selecteddate: inc,
    });
  };

  decDate = (event) => {
    let dec = this.state.selecteddate.subtract('days', 1);
    this.setState({
      selecteddate: dec,
    });
  };

  toggleChecked = () => {
    this.setState({ showchecked: !this.state.showchecked });
  };

  checkWork = (index) => {
    this.props.firebase.setWorkDone(index);
    this.setState({ clicked: index });
  };

  setMessage = (value) => {
    this.setState({ message: value });
  };

  acceptTerms = (value) => {
    this.setState({ terms: value });
  };

  componentDidMount() {
    document.body.classList.remove('login-page')
    this.unsubscribe = this.props.firebase
      .getUserWorks()
      .onSnapshot(dataSnapshot => {
          let _datas = []
          dataSnapshot.forEach(doc =>
            _datas.push({ ...doc.data(), id: doc.id }),
          )

          let handledaggregated = {};
          let nothandledaggregated = { all: [] };
          for (let i = 0; i < _datas.length; i++) {
            let h = _datas[i]
            if (h.start) {
              h.date = moment(h.start).format('YYYY-MM-DD')
            }
            if (!h.checked && !h.away) {
              if (!nothandledaggregated[h.date])
                nothandledaggregated[h.date] = [];
              const nhRow = (<Row key={h.id}>
                <Col className='border-bottom pb-2 pt-2' xs='3'>
                {(!h.status ? 
                  <FormGroup check>
                    <Label check>
                      <Input
                        type='checkbox'
                        onChange={(e) => this.checkWork(h.id)}
                      />
                      <span className='form-check-sign'>
                        <span className='check'></span>
                      </span>
                      {h.type === 'coaching' ? (
                        <SportsBaseballOutlinedIcon />
                      ) : (
                        <AccessTime />
                      )}
                    </Label>
                    </FormGroup>
                    : <div>Hyväksynnässä: {h.status}</div>)}
                </Col>
                <Col className='border-bottom pb-2 pt-2' xs='9'>
                  {moment(h.start).format('DD.MM. HH:mm')} -{' '}
                  {moment(h.end).format('HH:mm')} {h.task}
                  <ChevronRightIcon
                    onClick={() => {
                      this.props.history.push('/user/det/' + h.id)
                      // document.location.href = '/user/det/' + h.id;
                    }}
                  />
                </Col>
              </Row>)
              nothandledaggregated[h.date].push(nhRow);
              nothandledaggregated.all.push(nhRow);
            } else if (h.checked || h.away) {
              if (!handledaggregated[h.date]) handledaggregated[h.date] = [];
              handledaggregated[h.date].push(
                <Row key={h.id}>
                  <Col className='border-bottom pb-2 pt-2' sm='12'>
                    {h.type === 'coaching' ? (
                      <SportsBaseballOutlinedIcon />
                    ) : (
                      ''
                    )}{' '}
                    {moment(h.start).format('HH:mm')} -{' '}
                    {moment(h.end).format('HH:mm')} {h.task}
                    {h.away ? (' Poissaolo') : ('')}
                    {' '}
                    <ChevronRightIcon
                      onClick={() => {
                        this.props.history.push('/user/det/' + h.id)
                      }}
                    />
                  </Col>
                </Row>
              );
            }
          }

          this.setState({
            data: _datas,
            loading: false,
            nothandled: nothandledaggregated,
            handled: handledaggregated,
          });
      });
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  getAmountofHandled = () => {
    if (this.state.handled[this.state.selecteddate.format(DATEFORMAT)]) {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)]
        .length;
    } else {
      return 0;
    }
  };

  showHandledList = () => {
    if (
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)] &&
      this.state.handled[this.state.selecteddate.format(DATEFORMAT)].length < 1
    ) {
      return <></>;
    } else {
      return this.state.handled[this.state.selecteddate.format(DATEFORMAT)];
    }
  };

  createNotHandledList = (today) => {
    if (today) {
      if (
        this.state.nothandled[this.state.selecteddate.format(DATEFORMAT)] &&
        this.state.nothandled[this.state.selecteddate.format(DATEFORMAT)].length >
        0
      ) {
        return this.state.nothandled[this.state.selecteddate.format(DATEFORMAT)];
      } else {
        return <div>Ei kuittaamattomia</div>;
      }
    } else {
      if (this.state.nothandled.all && this.state.nothandled.all.length > 0) {
        return this.state.nothandled.all
      } else {
        return <div>Ei kuittaamattomia</div>;
      }
    }
  };

  logOut = () => {
    this.props.firebase.doSignOut();
    this.props.history.push('/auth/login');
  };

  userNav = () => {
    return (
      <Nav>
        <NavItem>
          <NavLink href='/admin/rota'>Työvuorolista</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='/admin/employees'>Henkilökunta</NavLink>
        </NavItem>
      </Nav>
    );
  };

  render() {
    if (!this.state.loading) {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' sm='12' lg='8' md='12' xl='8' xs='12'>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md='12' sm='12'>
                        Työajanseuranta / {this.props.user.email}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className='d-block d-sm-none' lg='12' md='12' sm='12' xs='12'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.decDate}
                        >
                          &lt;
                        </Button>
                        
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.incDate}
                        >
                          &gt;
                        </Button>{' '}
                        {this.state.selecteddate.format('DD-MM-YYYY dddd')}
                      </Col>
                      <Col className='d-none d-sm-block' lg='2' md='2' sm='1'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.decDate}
                        >
                          &lt;
                        </Button>
                      </Col>
                      <Col className='pl-5 pt-3 d-none d-sm-block' lg='8' md='8' sm='5'>
                        <strong>
                          {this.state.selecteddate.format('DD-MM-YYYY dddd')}
                        </strong>
                      </Col>
                      <Col className='text-right d-none d-sm-block' lg='2' md='2' sm='1'>
                        <Button
                          color='primary'
                          size='sm'
                          onClick={this.incDate}
                        >
                          &gt;
                        </Button>
                      </Col>
                    </Row>
                    {this.createNotHandledList(true)}
                    <Row color='primary'>
                      <Col className='pb-2 pt-3' sm='12'>
                        <CardTitle
                          className='pt-20'
                          tag='h5'
                          onClick={this.toggleChecked}
                        >
                          Kuitatut {this.getAmountofHandled()}{' '}
                          {this.state.showchecked ? (
                            <KeyboardArrowDownIcon
                              onClick={this.toggleChecked}
                            />
                          ) : (
                            <ChevronRightIcon onClick={this.toggleChecked} />
                          )}
                        </CardTitle>
                      </Col>
                    </Row>
                    {this.state.showchecked ? this.showHandledList() : <></>}
                  </CardBody>
                  <CardFooter>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className='ml-auto mr-auto' sm='12' lg='8' md='12' xl='8' xs='12'>
                <Card>
                  <CardBody>
                  <CardTitle tag="h6">
                      Kaikki kuittaamattomat (Eivät siirry palkanmaksuun)
                    </CardTitle>
                    {this.createNotHandledList()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </>
      );
    } else {
      return (
        <>
            <Row>
              <Col className='ml-auto mr-auto' sm='12' lg='8' md='12' xl='8' xs='12'>
                <Card className='card-login'>
                  <CardHeader>
                    <Spinner size='sm' color='secondary' /> Loading...
                  </CardHeader>
                </Card>
              </Col>
            </Row>
        </>
      );
    }
  }
}

export default withFirebase(Main)
