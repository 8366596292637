import React from 'react'
import moment from 'moment'
import 'moment/locale/fi'
import { v4 as uuidv4 } from 'uuid'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Input,
  //  Container,
  FormGroup,
  Label,
  Col,
  Row,
} from 'reactstrap'
import { withFirebase } from './components/Fb'
import TextField from '@material-ui/core/TextField'

import 'react-datepicker/dist/react-datepicker.css'
import AddCircleIcon from '@material-ui/icons/AddCircle'
// import ImageUploader from 'react-images-upload'

class TravelExpense extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startdate: moment(),
      enddate: moment(),
      start: moment().format('HH:00'),
      end: moment().add(1, 'hours').format('HH:00'),
      loading: true
    }
  }

  async componentDidMount() {
    if (this.props.computedMatch.params.id) {
      console.log('this is update: ' + this.props.computedMatch.params.id)
      let expense = await this.props.firebase.getUserTravelExpenseById(this.props.computedMatch.params.id)
      console.log(expense.data())
      this.setState({
        id: this.props.computedMatch.params.id,
        startdate: moment(expense.data().start),
        enddate: moment(expense.data().end),
        start: moment(expense.data().start).format('HH:mm'),
        end: moment(expense.data().end).format('HH:mm'),
        km: expense.data().km,
        route: expense.data().route,
        purpose: expense.data().purpose,
        abroadfull: expense.data().abroadfull,
        abroadhalf: expense.data().abroadhalf,
        homelandfull: expense.data().homelandfull,
        homelandhalf: expense.data().homelandhalf,
        expenserows: expense.data().rows,
        loading: false
      })
    } else {
      console.log('this is new')
      this.setState({ loading: false })
    }
  }
  setStartDate = (event) => {
    this.setState({ startdate: moment(event.target.value) })
  }

  setEndDate = (event) => {
    this.setState({ enddate: moment(event.target.value) })
  }

  setEndtime = (event) => {
    this.setState({ end: event.target.value })
  }

  setStarttime = (event) => {
    this.setState({ start: event.target.value })
  }

  setPurpose = (event) => {
    this.setState({ purpose: event.target.value })
  }

  setKm = (event) => {
    let _s = Number.parseInt(event.target.value)
    this.setState({ km: _s })
  }

  setRoute = (event) => {
    this.setState({ route: event.target.value })
  }

  setAmount = (event) => {
    let _s = Number.parseInt(event.target.value)
    let _k = event.target.name
    let _o = {}
    _o[_k] = _s
    this.setState(_o)
  }

  setDescription = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.costrows
    _a[index].description = event.target.value
    this.setState({ costrows: _a })
  }

  onSave = (event) => {
    event.preventDefault()
    console.log('Save')
    const travelexpense = {
      checked: false,
      purpose: this.state.purpose,
      employee: this.props.profile.displayName,
      email: this.props.profile.email,
      route: this.state.route,
      km: this.state.km ? this.state.km : 0,
      homelandfull: this.state.homelandfull ? this.state.homelandfull : 0,
      homelandhalf: this.state.homelandhalf ? this.state.homelandhalf : 0,
      abroadfull: this.state.abroadfull ? this.state.abroadfull : 0,
      abroadhalf: this.state.abroadhalf ? this.state.abroadhalf : 0,
      start:
        this.state.startdate.format('YYYY-MM-DD') +
        'T' +
        this.state.start,
      end:
        this.state.enddate.format('YYYY-MM-DD') +
        'T' +
        this.state.end,
      type: 'travelexpense',
      rows: this.state.expenserows,
    }
    const id = (this.state.id ? this.state.id : uuidv4())
    this.props.firebase
      .addTravelExpense(
        travelexpense,
        id
      )
      .then((dataSnapshot) => {
        this.props.history.push('/user/expensesreport')
      })
  }
  setSum = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.expenserows
    let _s = event.target.value
    _a[index].sum = _s
    this.setState({ expenserows: _a })
  }

  setDescription = (event) => {
    let index = Number.parseInt(event.target.name.split('.')[1])
    let _a = this.state.expenserows
    _a[index].description = event.target.value
    this.setState({ expenserows: _a })
  }

  onDrop = (pictureFiles, pictureDataURLs) => {
    this.setState({
      pictures: pictureFiles,
    })
  }


  addExpenseRow = () => {
    let j = (this.state.expenserows ? this.state.expenserows : [])
    j.push({})
    this.setState({ expenserows: j })
  }

  expenseRows = () => {
    if (!this.state.expenserows) {
      return(
        <Row>
          <Col style={{ marginTop: '20px' }}>
            <AddCircleIcon
              onClick={() => {
                this.addExpenseRow()
              }}
            />{' '}
            Lisää kuluja
          </Col>
        </Row>
      )
    } else {
      let erows = this.state.expenserows.map((row, index) => {
        let i = index
        return (
          <Row key={i} style={{ border: '1px solid rgba(0, 0, 0, 0.10)' }}>
            <Col sm='10' md='10' xs='10'>
              <FormGroup>
                <Label for={'description.' + i}>Kuvaus</Label>
                <Input
                  type='text'
                  name={'description.' + i}
                  id={'description.' + i}
                  onChange={this.setDescription}
                  value={(this.state.expenserows[i].description ? this.state.expenserows[i].description : '')}
                />
              </FormGroup>
            </Col>
            <Col md='2' sm='2' xs='2'>
              <FormGroup>
                <Label for={'sum.' + i}>Summa</Label>
                <Input
                  type='text'
                  name={'sum.' + i}
                  id={'sum.' + i}
                  onChange={this.setSum}
                  placeholder='0.00'
                  value={(this.state.expenserows[i].sum ? this.state.expenserows[i].sum : '')}
                />
              </FormGroup>
            </Col>
          </Row>
        )
      })
      erows.push((
        <Row key={9999999999}>
        <Col style={{ marginTop: '20px' }}>
          <AddCircleIcon
            onClick={() => {
              this.addExpenseRow()
            }}
          />{' '}
          Lisää kulurivi
        </Col>
      </Row>

      ))
      return erows
    }
  }


  render() {
    return (
      <>
        <Row>
          <Col
            className='ml-auto mr-auto'
            sm='12'
            lg='8'
            md='12'
            xl='8'
            xs='12'
          >
            <Card className='card-login'>
              <CardHeader>Matkalasku</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for='title'>Matkan tarkoitus</Label>
                      <Input
                        type='text'
                        name='title'
                        id='title'
                        placeholder='Matkan tarkoitus'
                        value={(this.state.purpose ? this.state.purpose : '')}
                        onChange={this.setPurpose}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col sm='6'>
                    <TextField
                      id='date'
                      label='Matka alkoi'
                      type='date'
                      onChange={this.setStartDate}
                      // defaultValue={moment().format('YYYY-MM-DD')}
                      value={(this.state.startdate ? moment(this.state.startdate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col sm='6'>
                    <TextField
                      id='starttime'
                      label='Alkuaika'
                      type='time'
                      onChange={this.setStarttime}
                      // defaultValue={moment().format('HH:00')}
                      value={(this.state.start ? this.state.start : '')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600, // 5 min
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <TextField
                      id='date'
                      label='Matka päättyi'
                      type='date'
                      onChange={this.setEndDate}
                      // defaultValue={moment().format('YYYY-MM-DD')}
                      value={(this.state.enddate ? moment(this.state.enddate).format('YYYY-MM-DD') : moment().add(1, 'hours').format('YYYY-MM-DD'))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Col>
                  <Col sm='6'>
                    <TextField
                      id='endtime'
                      label='Loppuaika'
                      type='time'
                      onChange={this.setEndtime}
                      // defaultValue={moment().add('hours', 1).format('HH:00')}
                      value={(this.state.end ? this.state.end : '')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 600, // 5 min
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Col md='2' sm='2'>
                    Päivärahat
                  </Col>
                  <Col md='2' sm='2'>
                    <FormGroup>
                      <Label for='homelandfull'>Kotimaa kokopäivä</Label>
                      <Input
                        type='text'
                        name='homelandfull'
                        id='homelandfull'
                        value={(this.state.homelandfull ? this.state.homelandfull : '')}
                        onChange={this.setAmount}
                        placeholder='kpl'
                      />
                    </FormGroup>
                  </Col>
                  <Col md='2' sm='2'>
                    <FormGroup>
                      <Label for='homelandhalf'>Kotimaa puolipäivä</Label>
                      <Input
                        type='text'
                        name='homelandhalf'
                        id='homelandhalf'
                        value={(this.state.homelandhalf ? this.state.homelandhalf : '')}

                        onChange={this.setAmount}
                        placeholder='kpl'
                      />
                    </FormGroup>
                  </Col>
                  <Col md='2' sm='2'>
                    <FormGroup>
                      <Label for='abroadfull'>Ulkomaa kokopäivä</Label>
                      <Input
                        type='text'
                        name='abroadfull'
                        id='abroadfull'
                        value={(this.state.abroadfull ? this.state.abroadfull : '')}
                        onChange={this.setAmount}
                        placeholder='kpl'
                      />
                    </FormGroup>
                  </Col>
                  <Col md='2' sm='2'>
                    <FormGroup>
                      <Label for='abroadhalf'>Ulkomaa puolipäivä</Label>
                      <Input
                        type='text'
                        name='abroadhalf'
                        id='abroadhalf'
                        value={(this.state.abroadhalf ? this.state.abroadhalf : '')}
                        onChange={this.setAmount}
                        placeholder='kpl'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='2' sm='2'>
                    Kilometrit
                  </Col>
                  <Col md='2' sm='2'>
                    <FormGroup>
                      <Label for='km'>Km</Label>
                      <Input
                        type='text'
                        name='km'
                        id='km'
                        value={(this.state.km ? this.state.km : '')}
                        onChange={this.setKm}
                        placeholder='0'
                      />
                    </FormGroup>
                  </Col>
                  <Col md='8' sm='8'>
                    <FormGroup>
                      <Label for='route'>Reitti</Label>
                      <Input
                        type='text'
                        name='route'
                        id='route'
                        value={(this.state.route ? this.state.route : '')}
                        onChange={this.setRoute}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/* {(this.state.expenserows ? 
                  <Row>
                  <Col md='12' sm='12'>
                    <ImageUploader
                      withIcon={false}
                      buttonText='Lisää kuitit'
                      withLabel={false}
                      onChange={this.onDrop}
                      imgExtension={[
                        '.jpg',
                        '.gif',
                        '.png',
                        '.gif',
                        '.pdf',
                        '.jpeg',
                      ]}
                      maxFileSize={5242880}
                      withPreview={true}
                    />
                  </Col>
                </Row>
                : null )} */}
                {this.expenseRows()}
                <Row className='pt-5'>
                  <Col className='ml-auto mr-auto' sm='8'>
                    <Button color='primary' onClick={this.onSave}>
                      Tallenna
                    </Button>
                  </Col>
                  <Col className='ml-auto mr-auto' sm='4'>
                    <Button
                      color='secondary'
                      onClick={() => {
                        this.props.history.push('/user/main')
                      }}
                    >
                      Peruuta
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default withFirebase(TravelExpense)
